import React, { ReactElement } from 'react'
import { CgPiano } from 'react-icons/cg'
import { FaDrum, FaMagic, FaVolumeMute, FaVolumeUp, FaChevronLeft, FaChevronRight } from 'react-icons/fa'

import GuitarIcon from '../../assets/icons/guitar.svg'
import InfiniteRepeatBoldIcon from '../../assets/icons/infinite-repeat-bold.svg'
import InfiniteRepeatIcon from '../../assets/icons/infinite-repeat.svg'
import LoaderIcon from '../../assets/icons/loader.svg'
import MetronomeIcon from '../../assets/icons/metronome.svg'
import NotesIcon from '../../assets/icons/notes2.svg'
import PauseIcon from '../../assets/icons/pause.svg'
import PianoRollMobileIcon from '../../assets/icons/piano-roll-mobile.svg'
import PianoRollIcon from '../../assets/icons/piano-roll.svg'
import PlayIcon from '../../assets/icons/play.svg'
import RepeatIcon from '../../assets/icons/repeat.svg'
import ResetIcon from '../../assets/icons/reset.svg'
import SettingsIcon from '../../assets/icons/settings2.svg'
import styles from './CircleButton.module.scss'

const iconTypes = [
  'piano',
  'magic',
  'play',
  'pause',
  'repeat',
  'infinite-repeat',
  'infinite-repeat-bold',
  'step-backward',
  'volume-up',
  'volume-mute',
  'drum',
  'chevron-left',
  'chevron-right',
  'metronome',
  'none',
  'piano-roll',
  'piano-roll-mobile',
  'settings',
  'guitar',
  'notes',
] as const
type IconType = typeof iconTypes[number]

const buttonSizes = ['small', 'medium', 'large'] as const
type ButtonSize = typeof buttonSizes[number]

type Props = {
  className?: string
  attributes?: any
  enabled?: boolean
  isHoverWhite?: boolean
  loading?: boolean
  icon?: IconType
  size?: ButtonSize
  onClick?: () => void
  children?: ReactElement
  style?: React.CSSProperties
}

const GetIcon = (icon?: IconType) => {
  switch (icon) {
    case 'piano':
      return <CgPiano className={styles.icon} />
    case 'guitar':
      return <GuitarIcon className={styles.icon} />
    case 'notes':
      return <NotesIcon className={styles.icon} />
    case 'magic':
      return <FaMagic className={styles.icon} />
    case 'piano-roll':
      return <PianoRollIcon className={styles.icon} />
    case 'piano-roll-mobile':
      return <PianoRollMobileIcon className={styles.icon} />
    case 'repeat':
      return <RepeatIcon className={styles.icon} />
    case 'infinite-repeat':
      return <InfiniteRepeatIcon className={styles.icon} />
    case 'infinite-repeat-bold':
      return <InfiniteRepeatBoldIcon className={styles.icon} />
    case 'play':
      return <PlayIcon className={styles.icon} />
    case 'settings':
      return <SettingsIcon className={styles.icon} />
    case 'pause':
      return <PauseIcon className={styles.icon} />
    case 'metronome':
      return <MetronomeIcon className={styles.icon} />
    case 'step-backward':
      return <ResetIcon className={styles.icon} />
    case 'volume-up':
      return <FaVolumeUp className={styles.icon} />
    case 'volume-mute':
      return <FaVolumeMute className={styles.icon} />
    case 'drum':
      return <FaDrum className={styles.icon}></FaDrum>
    case 'chevron-left':
      return <FaChevronLeft className={styles.icon}></FaChevronLeft>
    case 'chevron-right':
      return <FaChevronRight className={styles.icon}></FaChevronRight>
    default:
      return <></>
  }
}

const CircleButton: React.FC<Props> = (props) => {
  const classes = [styles.container, props.className]

  if (props.enabled) {
    classes.push(styles.enabled)
  }

  if (props.isHoverWhite) {
    classes.push(styles.isHoverWhite)
  }

  if (props.size === 'small') {
    classes.push(styles.small)
  } else if (props.size === 'medium') {
    classes.push(styles.medium)
  } else if (props.size === 'large') {
    classes.push(styles.large)
  }

  return (
    <div
      style={props.style}
      onClick={props.enabled ? props.onClick : () => void 0}
      className={classes.join(' ')}
      {...(props.attributes || {})}
    >
      {props.loading && <LoaderIcon className={styles.loader} />}
      {!props.loading && GetIcon(props.icon)}
      {props.children || null}
    </div>
  )
}

CircleButton.defaultProps = {
  className: '',
  enabled: true,
  isHoverWhite: true,
  icon: 'none',
  size: 'small',
  onClick: () => void 0,
}

export default CircleButton
