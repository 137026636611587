import { getApiChordProg } from '../utils/api'
import { Prog } from '../utils/types'
import api from './index'

// IN KEY

export const getChordEditorInKeyChords = (params: {
  diatonicChordTypes: string[]
  tonalityKey: string
  tonalityScale: string
}) => {
  return api.get(`${process.env.API_URL}/chord/diatonic`, { params: { ...params, octave: 3 } })
}
export const getChordEditorInKeyChordsInnerRoute = async (params: {
  diatonicChordTypes: string[]
  tonalityKey?: string
  tonalityScale?: string
}) => {
  const data = await api.post('/api/chord-editor/in-key', params)
  return data.data
}

// SEARCH

export const getChordEditorSearchChords = (params: { query: string; tonalityKey: string; tonalityScale: string }) => {
  return api.get(`${process.env.API_URL}/chord/search`, { params })
}
export const getChordEditorSearchChordsInnerRoute = async (params: {
  query: string
  tonalityKey?: string
  tonalityScale?: string
}) => {
  const data = await api.post('/api/chord-editor/search', params)
  return data.data
}

// AI SUGGESTIONS

export const getChordEditorAiSuggestionChords = ({
  prog,
  chordIndex,
  genre,
}: {
  prog: string
  chordIndex: string
  genre: string
}) => {
  return api.post(`${process.env.API_URL}/chord/suggestions`, {
    chordGenreKey: genre,
    prog: prog,
    chordIndex,
  })
}
export const getChordEditorAiSuggestionChordsInnerRoute = async ({
  prog,
  chordIndex,
  genre,
}: {
  chordIndex: number
  prog: Prog
  genre: string
}) => {
  const data = await api.post('/api/chord-editor/ai-suggestions', {
    genre,
    prog: getApiChordProg(prog),
    chordIndex,
  })
  return data.data
}
