import React, { ReactNode, useEffect, useRef, useState } from 'react'

import { usePianoRollPlayhead } from '../../ai-playground/InteractivePianoRoll/hooks/usePianoRollPlayhead'
import { drumsPianoRollId } from '../DrumsEditor/DrumsEditor'
import { melodyPianoRollId } from '../MelodyEditor/MelodyEditor'
import { usePlayerConfigState } from './usePlayerConfigState'

type FetchType = {
  time: number
  partDuration: number
  loop: number
  currentPart: number
}

const PlaybackTimePositionContext = React.createContext<FetchType>({
  loop: 1,
  time: 1,
  partDuration: 0,
  currentPart: 1,
})

export const PlaybackTimePositionProvider = ({ children }: { children: ReactNode }) => {
  const { playerConfig } = usePlayerConfigState()
  const { setPlayheadPositionTicks: setMelodyPlayheadPositionTicks } = usePianoRollPlayhead(melodyPianoRollId)
  const { setPlayheadPositionTicks: setDrumsPlayheadPositionTicks } = usePianoRollPlayhead(drumsPianoRollId)

  const requestRef = useRef<number>()
  const melodyInteractivePianoRollRequestRef = useRef<number>()
  const drumsInteractivePianoRollRequestRef = useRef<number>()

  const [val, setVal] = useState(playerConfig.requestPosition())

  const getPosition = () => playerConfig.requestPosition()
  const animate = () => {
    setVal(() => getPosition())
    requestRef.current = requestAnimationFrame(animate)
  }
  const getInteractivePianoRollPosition = () => playerConfig.player.getTransportPositionTicks()
  const animateMelodyInteractivePianoRoll = () => {
    setMelodyPlayheadPositionTicks(getInteractivePianoRollPosition())
    melodyInteractivePianoRollRequestRef.current = requestAnimationFrame(animateMelodyInteractivePianoRoll)
  }
  const animateDrumsInteractivePianoRoll = () => {
    setDrumsPlayheadPositionTicks(getInteractivePianoRollPosition())
    drumsInteractivePianoRollRequestRef.current = requestAnimationFrame(animateDrumsInteractivePianoRoll)
  }

  useEffect(() => {
    setVal(getPosition())
    requestRef.current = requestAnimationFrame(animate)

    return () => {
      if (requestRef.current === undefined) {
        return
      }
      cancelAnimationFrame(requestRef.current)
    }
  }, [playerConfig.isPartLooped, playerConfig.prog, playerConfig.currentPartId || null])
  useEffect(() => {
    setMelodyPlayheadPositionTicks(getInteractivePianoRollPosition())
    melodyInteractivePianoRollRequestRef.current = requestAnimationFrame(animateMelodyInteractivePianoRoll)

    setDrumsPlayheadPositionTicks(getInteractivePianoRollPosition())
    drumsInteractivePianoRollRequestRef.current = requestAnimationFrame(animateDrumsInteractivePianoRoll)

    return () => {
      cancelAnimationFrame(melodyInteractivePianoRollRequestRef.current as any)
      cancelAnimationFrame(drumsInteractivePianoRollRequestRef.current as any)
    }
  }, [playerConfig.isPartLooped, playerConfig.prog, playerConfig.currentPartId || null])
  return <PlaybackTimePositionContext.Provider value={val}>{children}</PlaybackTimePositionContext.Provider>
}

export const usePlaybackTimePosition = () => React.useContext<FetchType>(PlaybackTimePositionContext)
