import classNames from 'classnames'
import React from 'react'

import VolumeMutedIcon from '../../../assets/icons/volume-mute-new.svg'
import VolumeIcon from '../../../assets/icons/volume-new.svg'
import Slider from '../../common/Slider'
import styles from './VolumeControls.module.scss'

type Props = {
  color?: 'white' | 'blue' | 'purple' | 'green' | 'blue-marketing'
  volume: number
  onVolumeChange: (a: number) => void
  small?: boolean
  className?: string
  hide?: boolean
  muted?: boolean
  setMuted?: (a: boolean) => void
  hideControls?: boolean
  hideThumb?: boolean
  hideText?: boolean
  disableOnMute?: boolean
  onlyMute?: boolean
}

const VolumeControls: React.FC<Props> = ({ disableOnMute = true, ...props }) => {
  return (
    <div
      className={classNames(styles.sliderWithText, props.className || '', {
        [styles.hidden]: props.hide,
        [styles.hideControls]: props.hideControls,
        [styles.hideThumb]: props.hideThumb,
      })}
      data-disabled={props.muted}
      data-small={props.small}
    >
      {props.muted ? (
        <div
          onClick={() => {
            if (!props.setMuted) {
              return
            }
            props.setMuted(false)
          }}
          className={styles.muteButton}
        >
          <VolumeMutedIcon />
        </div>
      ) : (
        <div
          onClick={() => {
            if (!props.setMuted) {
              return
            }
            props.setMuted(true)
          }}
          className={styles.muteButton}
        >
          <VolumeIcon />
        </div>
      )}
      {!props.onlyMute ? (
        <>
          <Slider
            className={styles.slider}
            size='small'
            disabled={props.muted && disableOnMute}
            initialValue={props.volume}
            min={0}
            max={100}
            onChange={(value) => {
              props.onVolumeChange(value as number)
            }}
            color={props.color}
          />
          {!props.hideText && <span className={styles.text}>{props.volume}</span>}
        </>
      ) : null}
    </div>
  )
}

VolumeControls.defaultProps = {
  color: 'white',
}

export default VolumeControls
