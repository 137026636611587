import { getChordEditorSearchChordsInnerRoute } from '../api/chord-editor'
import { TChordEditorChord } from '../components/editor/NewChordEditor/components/ChordGrid'
import { convertNoteToMidi } from './audio/audioUtils'
import { getChordsFromAllParts } from './progUtils'
import { copyObj } from './stringUtils'
import { Chord, Prog } from './types'

const SCALES: { [key: string]: number[] } = {
  major: [0, 2, 4, 5, 7, 9, 11],
  minor: [0, 2, 3, 5, 7, 8, 10],
}

const getScaleNotes = (rootMIDI: number, scalePattern: number[]) => {
  return scalePattern.map((interval) => (rootMIDI + interval) % 12)
}

export const getChordNotesNotInScale = (chord: Chord, key = 'C', scale = 'major') => {
  const chordMidis = chord.midi
  const keyMIDI = convertNoteToMidi(key + '1')
  const scalePattern = SCALES[scale]

  const scaleNotes = getScaleNotes(keyMIDI, scalePattern)
  const chordNotesNormalized = chordMidis.map((note) => note % 12)
  const uniqueChordNotes = Array.from(new Set(chordNotesNormalized))

  const numberOfNotesNotInScale = uniqueChordNotes.filter((note) => !scaleNotes.includes(note))

  return numberOfNotesNotInScale
}
export const getNumOfChordNotesNotInScale = (chord: Chord, key = 'C', scale = 'major') => {
  return getChordNotesNotInScale(chord, key, scale).length
}
export const isChordInKeyScale = (chord: Chord, key: string, scale: string) => {
  return getNumOfChordNotesNotInScale(chord, key, scale) === 0
}

const chordToName = (chord: Chord) => chord.name
const chordToPitchClass = (chord: Chord) => {
  const chordPitchClasses = copyObj(chord.midi).map((midi: number) => midi % 12)
  const uniqueCPC = Array.from(new Set(chordPitchClasses)).sort()

  return uniqueCPC.join(',')
}

export const checkIfChordsAreSame = (chord_1: Chord, chord_2: Chord) => {
  const sameByName = chordToName(chord_1) === chordToName(chord_2)
  const sameByPitchClass = chordToPitchClass(chord_1) === chordToPitchClass(chord_2)

  return sameByName || sameByPitchClass || false
}
export const checkIfChordIsInScale = (
  activeChord: Chord | null | undefined,
  data: { [key: string]: { [key: string]: TChordEditorChord[] } },
  key: string | undefined,
  scale: string | undefined,
) => {
  if (!activeChord || !key || !scale) return { inScaleOptions: [], isInScale: false }

  const scaleKey = `${key}-${scale}`.toLowerCase()
  const scaleData = data[scaleKey] || {}
  const inScaleOptions = Object.entries(scaleData)
    .filter(([_, chords]) => chords.some((chord) => checkIfChordsAreSame(chord, activeChord)))
    .map(([option, _]) => option)
  const isInScale = inScaleOptions.length !== 0

  return { inScaleOptions, isInScale }
}

export const getScaleFromDegree = (degree: string) => {
  return (degree.match(/\|(.*?)\|/) || [])[1] || ''
}
export const removeScaleFromDegree = (degree: string) => {
  return degree.replace(/\|.*?\|/, '')
}
export const getLevelByProbability = (probability: number) => {
  if (probability >= 75) return 'perfect'
  if (probability >= 50) return 'medium'
  return 'low'
}

export const improveProgChords = async (prog: Prog) => {
  const query = getChordsFromAllParts(prog)
    .map((chord) => chord.name)
    .join(' - ')

  const foundChords: Chord[] = await getChordEditorSearchChordsInnerRoute({
    query,
    tonalityKey: prog.key,
    tonalityScale: prog.scale,
  }).then((res) => res.map((suggestion: any) => suggestion.chord))

  const newProg = copyObj(prog) as Prog
  newProg.parts.forEach((part, partIndex) => {
    part.chords.map((chord, chordIndex) => {
      const newChord = foundChords.find((foundChord) => checkIfChordsAreSame(chord, foundChord))
      if (!newChord) return
      newProg.parts[partIndex].chords[chordIndex] = { ...newChord, id: chord.id }
    })
  })

  return newProg
}
