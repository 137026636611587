import React from 'react'

import { ProgPart } from '../../../../utils/types'
import { usePlaybackTimePosition } from '../../hooks/usePlaybackTimePosition'
import { usePlayerConfigState } from '../../hooks/usePlayerConfigState'
import styles from './PartsMenuItemProgress.module.scss'

const LoadingTick: React.FC<{
  duration: number
  position: number
  active?: boolean
  filled?: boolean
}> = ({ duration, position, active, filled }) => {
  const getWidth = () => {
    if (filled) {
      return '100%'
    }
    if (!active) {
      return 0
    }
    return `${Math.min(100, (Math.max(0, position) / duration) * 100)}%`
  }
  return (
    <div className={styles.tick}>
      <div
        className={styles.tickBody}
        style={{
          width: getWidth(),
        }}
      />
    </div>
  )
}

type Props = {
  part: ProgPart
}
const PartsMenuItemProgress: React.FC<Props> = ({ part }) => {
  const { playerConfig } = usePlayerConfigState()
  const position = usePlaybackTimePosition()
  return (
    <PartsMenuItemProgressSimple part={part} playbackPosition={position} isPartLooped={playerConfig.isPartLooped} />
  )
}

type PropsSimple = {
  part: ProgPart
  isPartLooped?: boolean
  playbackPosition: any
}
export const PartsMenuItemProgressSimple: React.FC<PropsSimple> = ({ part, isPartLooped, playbackPosition }) => {
  const { time, loop, partDuration, currentPart } = playbackPosition || {}
  if (!part || currentPart !== part.id) {
    return null
  }
  return (
    <div className={styles.container}>
      {new Array(isPartLooped ? 1 : part.loops).fill(0).map((_, index) => (
        <LoadingTick
          key={index}
          duration={partDuration}
          position={time - 1}
          active={index + 1 === loop}
          filled={index + 1 < loop}
        />
      ))}
    </div>
  )
}

export default PartsMenuItemProgress
