import { IV3Preview } from '../server/src/models'
import { Preview } from '../utils/types'
import api from './index'
import { progressionJsonToProg } from './projects'

export function previewDbToProgression(preview: IV3Preview): null | Preview {
  if (!preview) return null

  let prog = null

  try {
    prog = progressionJsonToProg(preview.prog)
  } catch (e) {
    console.error(e)
  }

  if (!prog) return null

  return {
    id: preview._id,
    name: prog.name,
    key: preview.key!,
    prog,
    lyrics: preview.lyrics,
  }
}

export const getPreviewByIdKeyOrHashInnerRoute = async (id: string) => {
  const data = await api.get(`/api/previews/${id}`)
  return previewDbToProgression(data.data)
}
