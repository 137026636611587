import React from 'react'
import ReactSlider from 'react-slider'

import styles from './Slider.module.scss'

const sliderSizes = ['small', 'medium'] as const
type SliderSize = typeof sliderSizes[number]

const sliderColors = ['blue', 'purple', 'green', 'white', 'blue-marketing'] as const
type SliderColor = typeof sliderColors[number]

type Props = {
  className?: string
  color?: SliderColor
  initialValue?: number
  max?: number
  min?: number
  vertical?: boolean
  disabled?: boolean
  size?: SliderSize
  step?: number
  onChange?: (val: number | readonly number[], index: number) => void | undefined
  onAfterChange?: (val: number | readonly number[], index: number) => void | undefined
}

const Slider: React.FC<Props> = (props) => {
  const sliderClasses = [styles.slider]
  const thumbClasses = [styles.thumb]
  const trackClasses = [styles.track]

  if (props.color === 'blue') {
    sliderClasses.push('blue')
    thumbClasses.push('blue')
  } else if (props.color === 'purple') {
    sliderClasses.push('purple')
    thumbClasses.push('purple')
  } else if (props.color === 'green') {
    sliderClasses.push('green')
    thumbClasses.push('green')
  } else if (props.color === 'white') {
    sliderClasses.push('white')
    thumbClasses.push('white')
  } else if (props.color === 'blue-marketing') {
    sliderClasses.push('blue-marketing')
    thumbClasses.push('blue-marketing')
  }

  if (props.vertical) {
    sliderClasses.push(styles.vertical)
    thumbClasses.push(styles.vertical)
    trackClasses.push(styles.vertical)
  } else {
    sliderClasses.push(styles.horizontal)
    thumbClasses.push(styles.horizontal)
    trackClasses.push(styles.horizontal)
  }
  if (props.disabled) {
    sliderClasses.push(styles.disabled)
    thumbClasses.push(styles.disabled)
    trackClasses.push(styles.disabled)
  }
  if (props.size === 'small') {
    sliderClasses.push(styles.small)
    thumbClasses.push(styles.small)
    trackClasses.push(styles.small)
  } else if (props.size === 'medium') {
    sliderClasses.push(styles.medium)
    thumbClasses.push(styles.medium)
    trackClasses.push(styles.medium)
  }

  return (
    <div className={props.className}>
      <ReactSlider
        min={props.min}
        max={props.max}
        step={props.step}
        disabled={props.disabled}
        orientation={props.vertical ? 'vertical' : 'horizontal'}
        invert={props.vertical}
        value={props.initialValue}
        defaultValue={props.initialValue}
        className={sliderClasses.join(' ')}
        thumbClassName={thumbClasses.join(' ')}
        trackClassName={trackClasses.join(' ')}
        onChange={props.onChange}
        onAfterChange={props.onAfterChange}
        ariaLabel='Slider'
      />
    </div>
  )
}

Slider.defaultProps = {
  className: '',
  color: 'blue',
  initialValue: 0,
  max: 100,
  min: 0,
  size: 'small',
  step: 1,
  onChange: () => void 0,
}

export default Slider
