import { UniqueIdentifier } from '@dnd-kit/core'
import deepEqual from 'deep-equal'
import dynamic from 'next/dynamic'
import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react'

import Edit from '../../../assets/icons/chord-edit.svg'
import Duplicate from '../../../assets/icons/clone.svg'
// import LockIcon from '../../../assets/icons/lock-ai.svg'
// import UnlockIcon from '../../../assets/icons/lock-off.svg'
import Delete from '../../../assets/icons/trash.svg'
import { useCurrentUser } from '../../../context/CurrentUserContext'
import { useGeneratorModals } from '../../../context/GeneratorModalsContext'
import { useHistory } from '../../../context/HistoryContext'
import { useInternationalization } from '../../../context/InternationalizationContext'
import { FRONTEND_LIMIT_TYPES, useLimits } from '../../../context/LimitsContext'
import useSizes from '../../../hooks/useSizes'
import {
  duplicateChordByIdInProg,
  getActivePart,
  getChordByIdFromProg,
  insertChordInProg,
  replaceChordByIdInProg,
} from '../../../utils/progUtils'
import { Chord, Prog } from '../../../utils/types'
import { usePlayerConfigState } from './usePlayerConfigState'

const EditMenu = dynamic(() => import('../../common/EditMenu/EditMenu'))

export enum MENU_OPTION_TYPES {
  CUT = 'CUT',
  COPY = 'COPY',
  PASTE = 'PASTE',
  PASTE_BEFORE = 'PASTE_BEFORE',
  PASTE_AFTER = 'PASTE_AFTER',
  DUPLICATE = 'DUPLICATE',
  DELETE = 'DELETE',
  EDIT = 'EDIT',
  UNDO = 'UNDO',
  REDO = 'REDO',
  // LOCK = 'LOCK',
}

export type TMenuOption = {
  name: string
  commands?: {
    mac: string
    win: string
  }
  icon?: ReactNode
  disabled?: boolean
  action: (e?: KeyboardEvent) => void
}

type TTiles = (number | UniqueIdentifier)[]
type TWidths = { [id: number]: number }
type TEditingVoicing = null | { defaultMidi: number[]; midi: number[]; name: string }

type FetchType = {
  menuShown: boolean
  mobileMenuShown: boolean
  menuMode: 'full' | 'shorten'
  menuPosition: { left: number; top: number }
  activeMenuOptions: TMenuOption[]
  tiles: TTiles
  widths: TWidths
  playheadOnChordId: number
  addTileId: number
  editingVoicing: TEditingVoicing
  transitionChordAdderPosition: number
  setEditingVoicing: (v: TEditingVoicing) => void
  setAddTileId: (v: number) => void
  setWidths: (v: TWidths | ((v: TWidths) => TWidths)) => void
  setTiles: (v: TTiles | ((v: TTiles) => TTiles)) => void
  handleShowMenu: (e: React.MouseEvent<HTMLDivElement>) => void
  handleShowMobileMenu: () => void
  handleHideMenu: () => void
  handlePasteChords: (position: number, chords: Chord[]) => void
  handleReplaceChord: (id: number, chord: Chord, play?: boolean) => void
  handleDeleteChords: (ids?: number[]) => void
  handleDuplicateChords: (ids?: number[]) => void
  handleLockChord: (ids: number[], value: boolean) => void
  setPlayheadOnChordId: (v: number) => void
  handleShowTransitionChordAdder: (v: number) => void
  handleHideTransitionChordAdder: () => void
}

const EditMenuContext = React.createContext<FetchType>({
  menuShown: false,
  mobileMenuShown: false,
  menuMode: 'full',
  menuPosition: { left: 0, top: 0 },
  activeMenuOptions: [],
  tiles: [],
  widths: {},
  playheadOnChordId: -1,
  addTileId: 0,
  editingVoicing: null,
  transitionChordAdderPosition: -1,
  setEditingVoicing: () => {},
  setAddTileId: () => {},
  setWidths: () => {},
  setTiles: () => {},
  handleShowMenu: () => {},
  handleShowMobileMenu: () => {},
  handleHideMenu: () => {},
  handlePasteChords: () => {},
  handleReplaceChord: () => {},
  handleDeleteChords: () => {},
  handleDuplicateChords: () => {},
  handleLockChord: () => {},
  setPlayheadOnChordId: () => {},
  handleShowTransitionChordAdder: () => {},
  handleHideTransitionChordAdder: () => {},
})

export const validateShortcut = (key: string, e?: KeyboardEvent, shouldShiftBePressed = false) => {
  const isCtrlKey = e && (e.ctrlKey || e.metaKey)
  const isShiftKey = (e && e.shiftKey && shouldShiftBePressed) || (e && !e.shiftKey && !shouldShiftBePressed)
  const isActionKey = e && (e.key === key || e.key === key.toUpperCase())

  return !e || (isCtrlKey && isShiftKey && isActionKey)
}

export const EditMenuProvider = ({ children }: { children: ReactNode }) => {
  const { statePointer, undo, redo } = useHistory()
  const { text } = useInternationalization()
  const { currentUser } = useCurrentUser()
  const { isMobile, isTablet } = useSizes()
  const { playerConfig, playerConfigSetter } = usePlayerConfigState()
  const { undoRedoShown, setUndoRedoShown, setUndoRedoHelpModalOpen } = useGeneratorModals()
  const { isChordsLimited, triggerLimitCallback } = useLimits()

  const {
    view,
    activeChordIds,
    currentPart,
    prog,
    currentPartId,
    player,
    instrumentLoaded,
    drumsInstrumentLoaded,
    editChordId,
  } = playerConfig
  const { setView, handleTimelineChange, setActiveChordIds, setProg, setEditChordId } = playerConfigSetter

  const [tiles, setTiles] = useState<TTiles>([])
  const [widths, setWidths] = useState<TWidths>({})
  const [addTileId, setAddTileId] = useState(0)

  const [mobileMenuShown, setMobileMenuShown] = useState(true)

  const [menuShown, setMenuShown] = useState(false)
  const [menuMode, setMenuMode] = useState<'full' | 'shorten'>('full')
  const [menuPosition, setMenuPosition] = useState<{ left: number; top: number }>({ left: 0, top: 0 })
  const [menuTargetId, setMenuTargetId] = useState<number | null>(null)

  const [copyState, setCopyState] = useState<Chord[]>([])
  const [playheadOnChordId, setPlayheadOnChordId] = useState(-1)

  const [editingVoicing, setEditingVoicing] = useState<TEditingVoicing>(null)

  const [transitionChordAdderPosition, setTransitionChordAdderPosition] = useState(-1)
  const hideTransitionChordAdderTimeout = useRef<any>(null)

  const chordLocked = getChordByIdFromProg(editChordId.id || activeChordIds[0], prog)?.locked
  const STRINGS = text.timeline.editMenu

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      const isModalOpen = document.getElementsByClassName('center-modal').length > 0
      const isInstrumentsLoading = !instrumentLoaded || !drumsInstrumentLoaded
      const isTargetInput = e.target instanceof HTMLInputElement

      if (isModalOpen || isInstrumentsLoading || isTargetInput) return

      Object.values(menuOptions).forEach((option) => option.action(e))
    }
    document.addEventListener('keydown', onKeyDown)
    return () => {
      document.removeEventListener('keydown', onKeyDown)
    }
  }, [activeChordIds, currentPart, playheadOnChordId, copyState, statePointer])

  useEffect(() => {
    const chords = currentPart?.chords || []

    const newTiles = chords.map((chord) => chord.id)
    const newWidths = chords.reduce(
      (acc, ch) => ({
        ...acc,
        [ch.id]: ch.duration || 1,
      }),
      {},
    )

    setTiles(newTiles)
    setWidths(newWidths)
  }, [currentPart])

  // Limits functions
  const handleCheckIfLimited = (newProg: Prog) => {
    const oldProgPart = getActivePart(prog, currentPartId)
    const newProgPart = getActivePart(newProg, currentPartId)
    const isLimited = isChordsLimited(oldProgPart?.chords || [], newProgPart?.chords || [])

    if (isLimited) triggerLimitCallback(isLimited, FRONTEND_LIMIT_TYPES.chords)
    return isLimited
  }

  // Transition functions
  const handleShowTransitionChordAdder = (position: number) => {
    if (!hideTransitionChordAdderTimeout.current && transitionChordAdderPosition === position) return

    if (hideTransitionChordAdderTimeout.current) {
      clearTimeout(hideTransitionChordAdderTimeout.current)
      hideTransitionChordAdderTimeout.current = null
    }
    setTimeout(() => {
      setTransitionChordAdderPosition(position)
    }, 300)
  }
  const handleHideTransitionChordAdder = () => {
    hideTransitionChordAdderTimeout.current = setTimeout(() => {
      setTransitionChordAdderPosition(-1)
    }, 300)
  }

  // Service funtions
  const handleCopyChords = () => {
    setCopyState(currentPart.chords.filter((chord) => activeChordIds.includes(chord.id)))
  }

  const handleDeleteChords = (ids = activeChordIds) => {
    if (!ids.length) return

    if (editChordId.id && ids.includes(editChordId.id)) {
      setEditChordId({ id: null, center: null })
    }

    if (!undoRedoShown && currentUser && !currentUser.prefs.undoRedoShown && !isMobile) {
      setUndoRedoShown(true)
      setUndoRedoHelpModalOpen(true)
    }

    const newTiles = tiles.filter((tile) => !ids.includes(+tile)) as number[]
    const newWidths = { ...widths }
    ids.forEach((id) => delete newWidths[id])

    handleTimelineChange({ tiles: newTiles, widths: newWidths })

    if (activeChordIds.length && deepEqual(ids, activeChordIds)) setActiveChordIds([])
  }

  const handlePasteChords = (position: number, pasteChordsProps?: Chord[]) => {
    const chords = pasteChordsProps || copyState
    if (!chords.length || !prog) return

    let newProg = JSON.parse(JSON.stringify(prog)) as Prog
    chords.forEach((chord, i) => (newProg = insertChordInProg(chord, position + i, newProg, currentPartId)))

    const isLimited = handleCheckIfLimited(newProg)
    if (isLimited) return

    setProg(newProg)
  }

  const handleReplaceChord = (id: number, chord: Chord, play = false) => {
    const oldChord = getChordByIdFromProg(id, prog)
    const newProg = replaceChordByIdInProg(id, prog as Prog, { ...chord, draft: false }, currentPartId)
    setProg(newProg)
    if (!oldChord) {
      setAddTileId(id)
      setActiveChordIds([id])
      if (play) player.playChord(newProg, id)
    } else {
      if (play) player.playChord(newProg, id)
    }
  }

  const handleDuplicateChords = (ids = activeChordIds) => {
    const currentPartChords = currentPart.chords
    ids.sort(
      (a, b) =>
        currentPartChords.findIndex((chord) => chord.id === a) - currentPartChords.findIndex((chord) => chord.id === b),
    )

    const chords = ids.map((id) => getChordByIdFromProg(id, prog)).filter(Boolean) as Chord[]
    if (!chords.length || !prog) {
      return
    }

    const idsPositions = ids.map((id) => currentPartChords.findIndex((chord) => chord.id === id))
    const startPosition = Math.max(...idsPositions) + 1

    let newProg = JSON.parse(JSON.stringify(prog)) as Prog
    ids.forEach((id, i) => (newProg = duplicateChordByIdInProg(id, startPosition + i, newProg, currentPartId)))

    const isLimited = handleCheckIfLimited(newProg)
    if (isLimited) return

    setProg(newProg)

    if (chords.length === 1) player.playChord(newProg, chords[0].id)
  }

  const handleLockChord = (ids: number[], value: boolean) => {
    let newProg = playerConfig.prog as Prog
    for (const id of ids) {
      const chord = getChordByIdFromProg(id, prog)
      newProg = replaceChordByIdInProg(
        id,
        newProg,
        {
          ...chord!,
          locked: value,
        },
        playerConfig.currentPartId,
      )
    }
    playerConfigSetter.setProg(newProg)
  }

  // Action functions
  const handleCut = (e?: KeyboardEvent) => {
    if (!validateShortcut('x', e)) return

    e?.preventDefault()

    handleCopyChords()
    handleDeleteChords()
    handleHideMenu()
  }

  const handleCopy = (e?: KeyboardEvent) => {
    if (!validateShortcut('c', e)) return

    e?.preventDefault()

    handleCopyChords()
    handleHideMenu()
  }

  const handlePaste = (e?: KeyboardEvent) => {
    if (!validateShortcut('v', e) || !copyState.length) return

    e?.preventDefault()

    const index = currentPart.chords.findIndex((chord) => chord.id === playheadOnChordId) + 1

    handlePasteChords(index)
    handleHideMenu()
  }

  const handlePasteBefore = (e?: KeyboardEvent) => {
    if (e || !copyState.length) return

    const index = currentPart.chords.findIndex((chord) => chord.id === menuTargetId)

    handlePasteChords(index)
    handleHideMenu()
  }

  const handlePasteAfter = (e?: KeyboardEvent) => {
    if (e || !copyState.length) return

    const index = currentPart.chords.findIndex((chord) => chord.id === menuTargetId) + 1

    handlePasteChords(index)
    handleHideMenu()
  }

  const handleLock = (e?: KeyboardEvent) => {
    if (e) return

    handleLockChord(editChordId.id ? [editChordId.id] : activeChordIds, !chordLocked)
    handleHideMenu()
  }

  const handleDuplicate = (e?: KeyboardEvent) => {
    if (!validateShortcut('d', e)) return

    e?.preventDefault()

    handleDuplicateChords()
    handleHideMenu()
  }

  const handleDelete = (e?: KeyboardEvent) => {
    if (e && e.key !== 'Backspace' && e.key !== 'Delete') return

    e?.preventDefault()

    handleDeleteChords()
    handleHideMenu()
  }

  const handleEdit = (e?: KeyboardEvent) => {
    if (e) return

    setView({
      editorOpen: true,
      chordEditMode: view.chordEditMode || 'ai-suggestions',
    })
    handleHideMenu()
  }

  const handleUndo = (e?: KeyboardEvent) => {
    if (!validateShortcut('z', e)) return

    e?.preventDefault()
    e?.stopPropagation()

    setEditChordId({ id: null, center: null })

    undo()
  }

  const handleRedo = (e?: KeyboardEvent) => {
    if (!validateShortcut('z', e, true)) return

    e?.preventDefault()
    e?.stopPropagation()

    setEditChordId({ id: null, center: null })

    redo()
  }

  // Menu control functions
  const handleShowMenu = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault()

    if (menuShown) return

    // @ts-ignore
    let tile = e.target.closest('[data-tile=true]')
    let tileId = tile?.getAttribute('data-tile-id') || null

    const chord = getChordByIdFromProg(+tileId, prog)
    if (chord?.draft) {
      tile = null
      tileId = null
    }

    if (tileId && activeChordIds.length < 2) setActiveChordIds([+tileId])

    setMenuTargetId(+tileId)
    setMenuMode(tile ? 'full' : 'shorten')
    setMenuPosition({ left: e.clientX, top: e.clientY })
    setMenuShown(true)
  }

  const handleShowMobileMenu = () => {
    if (!mobileMenuShown) setMobileMenuShown(true)
  }

  const handleHideMenu = () => {
    setMenuShown(false)
    setMobileMenuShown(false)
  }

  // Options
  const menuOptions: { [key in keyof typeof MENU_OPTION_TYPES]: TMenuOption } = {
    [MENU_OPTION_TYPES.CUT]: {
      name: STRINGS.cut,
      commands: {
        mac: '⌘ + X',
        win: 'Ctrl + X',
      },
      action: handleCut,
    },
    [MENU_OPTION_TYPES.COPY]: {
      name: STRINGS.copy,
      commands: {
        mac: '⌘ + C',
        win: 'Ctrl + C',
      },
      action: handleCopy,
    },
    [MENU_OPTION_TYPES.PASTE]: {
      name: STRINGS.paste,
      commands: {
        mac: '⌘ + V',
        win: 'Ctrl + V',
      },
      disabled: !copyState.length,
      action: handlePaste,
    },
    [MENU_OPTION_TYPES.PASTE_BEFORE]: {
      name: STRINGS.pasteBefore,
      disabled: !copyState.length,
      action: handlePasteBefore,
    },
    [MENU_OPTION_TYPES.PASTE_AFTER]: {
      name: STRINGS.pasteAfter,
      disabled: !copyState.length,
      action: handlePasteAfter,
    },
    [MENU_OPTION_TYPES.DUPLICATE]: {
      name: STRINGS.duplicate,
      commands: {
        mac: '⌘ + D',
        win: 'Ctrl + D',
      },
      icon: <Duplicate />,
      action: handleDuplicate,
    },
    [MENU_OPTION_TYPES.DELETE]: {
      name: STRINGS.delete,
      commands: {
        mac: '⌫',
        win: '⌫',
      },
      icon: <Delete />,
      action: handleDelete,
    },
    [MENU_OPTION_TYPES.EDIT]: {
      name: STRINGS.edit,
      icon: <Edit />,
      action: handleEdit,
    },
    [MENU_OPTION_TYPES.UNDO]: {
      name: STRINGS.undo,
      action: handleUndo,
    },
    [MENU_OPTION_TYPES.REDO]: {
      name: STRINGS.redo,
      action: handleRedo,
    },
    // [MENU_OPTION_TYPES.LOCK]: {
    //   name: chordLocked ? STRINGS.unlock : STRINGS.lock,
    //   icon: chordLocked ? <LockIcon /> : <UnlockIcon />,
    //   action: handleLock,
    // },
  }

  const activeMenuOptions = useMemo(() => {
    if (isTablet)
      return [
        menuOptions.DELETE,
        menuOptions.DUPLICATE,
        // menuOptions.LOCK,
        menuOptions.EDIT,
      ]

    if (menuMode === 'shorten') return [menuOptions.PASTE]

    const fullForMultipleSelection = [
      menuOptions.CUT,
      menuOptions.COPY,
      // menuOptions.LOCK,
      menuOptions.PASTE_BEFORE,
      menuOptions.PASTE_AFTER,
      menuOptions.DUPLICATE,
      menuOptions.DELETE,
    ]

    if (activeChordIds.length > 1) return fullForMultipleSelection

    return [...fullForMultipleSelection, menuOptions.EDIT]
  }, [menuMode, isTablet, activeChordIds, currentPart, playheadOnChordId, copyState])

  return (
    <EditMenuContext.Provider
      value={{
        menuShown,
        mobileMenuShown,
        menuMode,
        menuPosition,
        activeMenuOptions,
        tiles,
        widths,
        playheadOnChordId,
        addTileId,
        editingVoicing,
        transitionChordAdderPosition,
        setEditingVoicing,
        setAddTileId,
        setWidths,
        setTiles,
        handleShowMenu,
        handleShowMobileMenu,
        handleHideMenu,
        handlePasteChords,
        handleReplaceChord,
        handleDeleteChords,
        handleDuplicateChords,
        handleLockChord,
        setPlayheadOnChordId,
        handleShowTransitionChordAdder,
        handleHideTransitionChordAdder,
      }}
    >
      {children}
      <EditMenu />
    </EditMenuContext.Provider>
  )
}

export const useEditMenu = () => React.useContext<FetchType>(EditMenuContext)
